import { Card } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

export default function Post(props) {
  return (
    <Card fluid={true}>
      <Card.Content>
        <Card.Header>{props.post.title}</Card.Header>
        <Card.Meta>{props.post.username}</Card.Meta>
        <Card.Description>{props.post.content}</Card.Description>
      </Card.Content>
    </Card>
  );
}
