import logo from "./logo.svg";
import "./App.css";
import Posts from "./pages/Posts";
import CreatePostModal from "./components/CreatePostModal";
import {
  Header,
  Icon,
  Container,
  Divider,
  Button,
  Segment,
} from "semantic-ui-react";

function App() {
  return (
    <>
      <Divider hidden={true} />
      <Container>
        <Segment clearing={true} basic={true}>
          <Header as="h2" floated="left">
            <Icon name="cloud" />
            <Header.Content>
              Cloudflare Posts
              <Header.Subheader>All Posts</Header.Subheader>
            </Header.Content>
          </Header>
          <Header as="h2" floated="right">
            <Header.Content>
              <CreatePostModal />
            </Header.Content>
          </Header>
        </Segment>
        <Posts />
      </Container>
    </>
  );
}

export default App;
