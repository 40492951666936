import axios from "axios";
import { useEffect } from "react";
import { Container } from "semantic-ui-react";
import Post from "../components/Post";
import React from "react";
import "semantic-ui-css/semantic.min.css";

export default function Posts() {
  const [posts, setPosts] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(async () => {
    const fetchPosts = async () => {
      return (
        await axios.get("https://post-api.gautamvenkatesh.workers.dev/posts")
      ).data;
    };
    setPosts(await fetchPosts());
    setLoading(false);
  }, []);

  return (
    <Container loading={loading}>
      {posts.map((post) => (
        <Post post={post} />
      ))}
    </Container>
  );
}
