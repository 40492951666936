import { Button, Modal, Header, Form } from "semantic-ui-react";
import React from "react";
import axios from 'axios';

export default function CreatePostModal() {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [name, setName] = React.useState('');
  const [content, setContent] = React.useState('')

  const submitPost = () => {
      const post = {
          title: title,
          username: name,
          content: content
      }
      axios.post('https://post-api.gautamvenkatesh.workers.dev/posts', post)
  }
  
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button>Create Post</Button>}
    >
      <Modal.Header>Create Post</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Form.Field onChange={(event) => setTitle(event.target.value)}>
              <label>Title</label>
              <input placeholder='Title' />
            </Form.Field>
            <Form.Field onChange={(event) => setName(event.target.value)}>
              <label>Name</label>
              <input placeholder='Your Name' />
            </Form.Field>
            <Form.TextArea onChange={(event) => setContent(event.target.value)} label="Content" placeholder="Content"/>
          </Form>
          <Header as="h5">
            *Note: Posts will take some time to load onto main page. Reload a couple times to see your post!
          </Header>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          content="Submit"
          labelPosition='right'
          icon='checkmark'
          onClick={() => {
            submitPost();
            setOpen(false)
          }}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
}
